import { Box, BoxProps } from "@biblioteksentralen/js-utils";
import { DecorativeImage, ImageWithMetadata } from "@libry-content/types";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { isImageWithMetadata } from "./SanityImage";
import { SanityImageCore } from "./SanityImageCore";

type Props = { image?: DecorativeImage | ImageWithMetadata; resolution: number; alt?: string } & BoxProps;

export function SanityImagePreservingAspectRatio({ image, resolution, boxShadow = "md", alt, ...chakraProps }: Props) {
  const { t } = useTranslation();
  if (!image?.asset) return null;

  return (
    <>
      <Box
        lineHeight={0}
        borderRadius="0.5em"
        boxShadow={boxShadow}
        position="relative"
        overflow="hidden"
        {...chakraProps}
      >
        <SanityImageCore image={image} resolution={resolution} aspectRatio="original" customNextImageProps={{ alt }} />
      </Box>
      {isImageWithMetadata(image) && image.creator && (
        // Use absolute positioned box; a wrapper around component breaks book list preview layout
        <Box
          fontStyle="italic"
          marginTop="0.1rem"
          fontSize="sm"
          opacity={0.8}
          position="absolute"
          top="100%"
          left="50%;"
          transform="translateX(-50%)"
          whiteSpace="nowrap"
        >
          {t("Foto: {opphavsPerson}", { opphavsPerson: image.creator })}
        </Box>
      )}
    </>
  );
}
