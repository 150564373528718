import { getImageDimensions, SanityImageDimensions, SanityImageSource } from "@sanity/asset-utils";

const fallbackValues: SanityImageDimensions = {
  width: 1,
  height: 1,
  aspectRatio: 1,
};

// If an image-asset is deleted in sanity studio the image field on the doucment (with alt-text etc) might still exist in the data, but will cause getImageDimensions to throw an error
export function safelyGetImageDimensions(image?: SanityImageSource): SanityImageDimensions {
  if (!image) return fallbackValues;
  try {
    return getImageDimensions(image);
  } catch (e) {
    console.error(e);
    return fallbackValues;
  }
}
